<template>
  <div class="contents register wedding">
    <div class="title flexB">
      <h2>지원서 관리</h2>
    </div>
    <div class="box one full">
      <div class="inputBox">
        <div class="flexL" v-if="editMode">
          <p class="bold">상태</p>

          <el-tag v-if="moment(startRegistration) > moment(new Date())" size="small">접수전</el-tag>
          <el-tag v-else-if="moment(endRegistration) < moment(new Date())" size="small">접수마감</el-tag>
          <el-tag v-else size="small">접수마감</el-tag>
          <!-- <el-button class="confirmBtn" type="primary" size="mini" v-if="status == 'request'">심사승인</el-button> -->
        </div>

        <div class="flexL">
          <p class="bold">
            지원서 제목
            <span>*</span>
          </p>
          <input type="text" v-model="title" />
        </div>

        <!-- <div class="flexL">
          <p class="bold">
            제목
            <span>*</span>
          </p>
          <p v-if="editMode">
            <router-link :to="`/admin/users/detail?id=${user._id}`">
              {{ user.username }}
              <span class="material-symbols-rounded">demography</span>
            </router-link>
          </p>
          <div v-else class="plannerInput">
            <p @click="handleUserModal()">{{ user.username }}</p>
          </div>
        </div>-->

        <div class="flexL">
          <p class="bold">
            접수 기간
            <span>*</span>
          </p>
          <div class="region">
            <el-date-picker
              v-model="startDate"
              type="date"
              format="yyyy-MM-dd"
              placeholder="선택해 주세요."
              class="fullW"
            ></el-date-picker>

            <el-date-picker
              v-model="endDate"
              type="date"
              format="yyyy-MM-dd"
              placeholder="선택해 주세요."
              class="fullW"
            ></el-date-picker>
          </div>
        </div>

        <div class="flexL">
          <p class="bold">
            교육 기간
            <span>*</span>
          </p>
          <div class="region">
            <el-date-picker
              v-model="startRegistration"
              type="date"
              format="yyyy-MM-dd"
              placeholder="선택해 주세요."
              class="fullW"
            ></el-date-picker>

            <el-date-picker
              v-model="endRegistration"
              type="date"
              format="yyyy-MM-dd"
              placeholder="선택해 주세요."
              class="fullW"
            ></el-date-picker>
          </div>
        </div>

        <div class="flexL double">
          <p class="bold">
            소개말
            <span>*</span>
          </p>
          <textarea v-model="comments" />
        </div>

        <div class="flexL">
          <p class="bold">
            커리큘럼 이미지
            <span>*</span>
          </p>
          <div class="imgBox">
            <ul>
              <li>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="image"
                  @change="sendFile('image')"
                  id="imgUpload"
                  accept="image/*"
                />
              </li>
              <li v-for="(image, i) in images" :key="i">
                <img :src="image" alt="업로드이미지" />
                <a class="cancel" @click="handleDeleteFile(image, 'images')">
                  <img src="@/assets/images/icon_svg/ic_delete_wh.svg" alt="icon" />
                </a>
              </li>
            </ul>
            <!-- <em class="notice"
              >대표사진은 1:1 비율로 업로드 됩니다. ex) 150 * 150</em
            >-->
          </div>
        </div>
        <!-- <div class="flexL">
          <p class="bold">대표이미지/로고</p>
          <div class="imgBox">
            <ul>
              <li v-if="thumbnail">
                <img :src="thumbnail" />
                <a class="cancel" @click="handleDeleteFile(thumbnail, 'thumbnail')">
                  <img src="@/assets/images/icon_svg/ic_delete_wh.svg" alt="icon" />
                </a>
              </li>
              <li v-else>
                <label for="imgUpload">
                  <span class="material-icons addBtn">add</span>
                </label>
                <input
                  type="file"
                  ref="thumbnail"
                  @change="sendFile('thumbnail')"
                  id="imgUpload"
                  accept="image/*"
                />
              </li>
            </ul>
            <em class="notice">대표사진은 1:1 비율로 업로드 됩니다. ex) 150 * 150</em>
          </div>
        </div>-->
      </div>
      <div class="buttonWrap">
        <router-link to="/admin/apply/manage">목록</router-link>
        <button class="basic" @click="submit">{{ editMode == true ? "수정" : "등록" }}</button>
        <button class="point delete" @click="deleteAppy()">삭제</button>
      </div>
    </div>
  </div>
</template>

<script>
import region from "@/config/region.json";

import moment from "moment";
import { uploadFile, deleteFile } from "@/api/file";
import {
  createLecture,
  updateLecture,
  fetchLecture,
  removeLecture,
} from "@/api/admin";
import { format } from "@/mixins/format";
import { mapState } from "vuex";

export default {
  mixins: [format],

  data() {
    return {
      editMode: false,
      moment: moment,
      modalVisible: "",
      lectureId: "",
      bnfId: "",
      type: "",
      title: "",
      startDate: "",
      endDate: "",
      services: [],
      startRegistration: "",
      endRegistration: "",
      status: "",
      comments: "",
      file: "",
      images: [],
      thumbnail: "",
      currentPage: 1,
      total: 0,
      modalPage: 1,
      modalTotal: 0,
      searchResults: [],
      searchWord: "",
      user: "",
      bnfList: [],
      businessRegistration: "",
      regions: region,
      selectedMajor: "",
      selectedMinor: "",
      minorOptions: [],
    };
  },
  // watch: {
  //   fee: function (newValue) {
  //     const result = newValue
  //       .toString()
  //       .replace(/\D/g, "")
  //       .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //     this.fee = result;
  //   },
  // },
  created() {
    this.$store.dispatch("SET_NAVBAR", "3");
  },
  computed: {
    ...mapState(["_id", "username"]),
  },

  mounted() {
    if (this.$route.query.id != null) {
      this.editMode = true;
      this.lectureId = this.$route.query.id;
      this.getLecture();
    }
  },
  methods: {
    downloadFile() {
      const fileUrl = this.businessRegistration;
      // 파일 다운로드 처리
      const a = document.createElement("a");
      a.href = fileUrl;
      a.click();
    },
    openImage() {
      window.open(this.businessRegistration, "_blank");
    },
    updateMinorSelect() {
      this.selectedMinor = "";
      this.minorOptions = this.regions[this.selectedMajor] || [];
    },
    handleRegion() {
      this.region = this.selectedMajor + " " + this.selectedMinor;
    },
    handleUserModal() {
      this.modalVisible = "USER";
    },
    handleBnfModal(bnfId) {
      if (bnfId) {
        this.bnfId = bnfId;
      }
      this.modalVisible = "BNF";
    },
    uncomma(str) {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    },
    close() {
      this.modalVisible = "";
      this.bnfId = null;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },

    sendFile(type) {
      if (this.file == undefined) {
        return;
      }
      if (type == "image") {
        this.file = this.$refs.image.files[0];
      } else {
        this.file = this.$refs.thumbnail.files[0];
      }
      let formdata = new FormData();
      formdata.append("file", this.file);

      uploadFile(formdata).then(async (res) => {
        if (res.status == 200) {
          if (res.data.status == 200) {
            if (type == "image") {
              this.images.push(res.data.url);
            } else {
              this.thumbnail = res.data.url;
            }
            this.file = "";
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
            return;
          }
        } else {
          this.$alert("서버오류 입니다. 다시 파일을 업로드 해 주세요.", {
            confirmButtonText: "확인",
            center: true,
          });
          return;
        }
      });
    },
    handleDeleteFile(url, type) {
      this.$confirm("이미지를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        // if (this.lectureId) {
        //   deletePostFile(this.lectureId, url, type).then((res) => {
        //     if (res.data.status == 200) {
        //       if (type == "file") {
        //         this.businessRegistration = "";
        //       } else if (type == "images") {
        //         this.images.splice(this.images.indexOf(url), 1);
        //       }
        //     } else {
        //       this.$alert(res.data.message, {
        //         confirmButtonText: "확인",
        //         center: true,
        //       });
        //     }
        //   });
        // } else {
        deleteFile(url).then((res) => {
          if (res.data.status == 200) {
            if (type == "thumbnail") {
              this.thumbnail = "";
            } else if (type == "images") {
              this.images.splice(this.images.indexOf(url), 1);
            }
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      });
    },
    // handleDelete() {
    //   this.$confirm("해당 플래너의 정보는 유지되며, 상태만 변경 됩니다.", {
    //     confirmButtonText: "확인",
    //     cancelButtonText: "취소",
    //     center: true,
    //   }).then(() => {
    //     removePlanner(this.lectureId).then((res) => {
    //       if (res.data.status == 200) {
    //         this.$notify({
    //           title: "Success",
    //           message: "지원서정보가 변경 되었습니다.",
    //           type: "success",
    //         });
    //         this.$router.replace("/admin/plannermanage");
    //       }
    //     });
    //   });
    // },
    getLecture() {
      fetchLecture(this.lectureId).then((res) => {
        if (res.data.status == 200) {
          this.user = res.data.data.user;
          this.startDate = res.data.data.startDate;
          this.endDate = res.data.data.endDate;
          this.startRegistration = res.data.data.startRegistration;
          this.endRegistration = res.data.data.endRegistration;
          this.title = res.data.data.title;
          this.comments = res.data.data.comments;
          this.images = res.data.data.images;
          this.thumbnail = res.data.data.thumbnail;
          this.status = res.data.data.status;
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },

    submit() {
      if (this.title == "") {
        return this.$alert("지원서 제목을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      } else if (this.startDate == "") {
        return this.$alert("접수 시작일을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      } else if (this.endDate == "") {
        return this.$alert("접수 마감일을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      } else if (this.startRegistration == "") {
        return this.$alert("교육 시작일을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      } else if (this.endRegistration == "") {
        return this.$alert("교육 마감일을 입력해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
      }

      let data = {
        lectureId: this.lectureId,
        title: this.title,
        startDate: this.startDate,
        endDate: this.endDate,
        startRegistration: this.startRegistration,
        endRegistration: this.endRegistration,
        comments: this.comments,
        images: this.images,
      };
      if (this.editMode) {
        updateLecture(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("지원서 정보가 반영 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/apply/manage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      } else {
        createLecture(data).then((res) => {
          if (res.data.status == 200) {
            this.$alert("지원서 등록이 완료 되었습니다.", {
              confirmButtonText: "확인",
              center: true,
            }).then(() => {
              this.$router.push("/admin/apply/manage");
            });
          } else {
            this.$alert(res.data.message, {
              confirmButtonText: "확인",
              center: true,
            });
          }
        });
      }
    },
    async deleteAppy() {
      await this.$confirm("지원서를 삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      });

      if (this.images.length > 0 || this.thumbnail) {
        this.$alert("이미지와 로고를 모두 삭제 후 진행해 주세요.", {
          confirmButtonText: "확인",
          center: true,
        });
        return;
      }

      let data = {
        lectureId: this.lectureId,
      };
      removeLecture(data).then((res) => {
        if (res.data.status == 200) {
          this.$alert("지원서 데이터가 삭제 되었습니다.", {
            confirmButtonText: "확인",
            center: true,
          }).then(() => {
            this.$router.push("/admin/apply/manage");
          });
        } else {
          this.$alert(res.data.message, {
            confirmButtonText: "확인",
            center: true,
          });
        }
      });
    },

    userSelect(data) {
      this.user = data;
      this.close();
    },
  },
};
</script>
